<template>
  <div
    class="c-error-message u-flex-inline u-flex-align-center"
  >
    <Icon
      :icon="iconAlert"
      :size="16"
      fill="error"
      class="u-mr--xs"
    />
    {{ text }}
  </div>
</template>

<script>
import iconAlert from '@ds/svg/icons/bold/alert-circle-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconAlert
    }
  }
}
</script>

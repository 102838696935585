<template>
  <form
    class="c-sign-up u-flex u-flex-justify-center u-flex-direction-column u-flex-align-center o-content-box"
    novalidate
    @submit.prevent="submitForm"
  >
    <h2 class="u-text-align--center">
      {{ model.heading }}
    </h2>
    <template v-if="success">
      <Icon
        class="u-mb--xs"
        :icon="iconCircleCheck"
        :size="32"
        :fill="'success'"
      />
      <div v-html="model.successfulSubscriptionText"></div>
      <p
        v-if="model.unSubscribeInfoText"
        class="u-m--0"
      >
        {{ model.unSubscribeInfoText }}
        <a
          v-if="model.unSubscribeLink"
          class="u-text-underline"
          :href="model.unSubscribeLink.url"
        >
          {{ model.unSubscribeLink.text }}
        </a>.
      </p>
    </template>
    <template v-if="error">
      <div v-html="model.unexpectedErrorText"></div>
      <p class="u-m--0">
        <span
          class="u-text-underline"
          @click="resetSignUpForm()"
        >
          {{ model.tryAgainText }}
        </span>
      </p>
    </template>
    <template v-if="!success && !error">
      <div class="c-text-input-container u-width-100 u-width-450">
        <Icon
          :icon="iconEmail"
          :size="16"
          stroke="black"
          class="c-sign-up__icon"
        />
        <input
          v-model="email"
          :class="{
            'c-text-input--error': emailInvalid,
            'c-text-input--success': emailValid
          }"
          class="c-text-input c-sign-up__email u-max-width-100"
          :placeholder="model.emailPlaceholder"
          type="email"
          @blur="onInputBlur()"
        >
        <FormErrorLine
          v-if="emailInvalid"
          class="u-mt--s u-mb--xs"
          :text="model.emailVerificationError"
        />
      </div>
      <div
        v-show="mailingListsShown"
        class="u-flex u-flex-justify-center u-flex-align-center u-flex-wrap u-flex-justify-start@mobile u-mb--m u-mb--l@mobile"
      >
        <div
          v-for="(key, value) in model.mailingLists"
          :key="value"
          class="c-checkbox u-mr--s u-mt--s"
          :class="{'c-checkbox--error': !mailings.length}"
        >
          <input
            :id="value"
            v-model="mailings"
            :value="value"
            class="c-checkbox__input"
            type="checkbox"
          >
          <label
            class="c-checkbox__label"
            :for="value"
          >
            {{ key }}
          </label>
          <span class="c-checkbox-icon">
            <Icon
              :icon="iconCheck"
            />
          </span>
        </div>
      </div>
      <div
        class="u-flex u-flex-justify-center u-flex-align-center"
        :class="{
          'u-mt--m': !mailingListsShown
        }"
      >
        <div class="c-checkbox-container">
          <div
            class="c-checkbox"
            :class="{'c-checkbox--error': !termsValid}"
          >
            <input
              id="check"
              v-model="terms"
              class="c-checkbox__input"
              type="checkbox"
              @change="termsValid = terms"
            >
            <label
              class="c-checkbox__label"
              for="check"
            >
              <span>
                {{ model.acceptText }}
                <a
                  v-if="model.consentText"
                  href="#"
                  @click.prevent="termsModal = !termsModal"
                >
                  {{ model.acceptTextLink }}
                </a>
              </span>
            </label>
            <span class="c-checkbox-icon">
              <Icon
                :icon="iconCheck"
              />
            </span>
          </div>
          <FormErrorLine
            v-if="!termsValid"
            class="c-checkbox__validate-message u-mt--s u-mb--xs"
            :text="model.consentVerificationError"
          />
        </div>
        <button
          class="c-button c-button--dark u-ml--s u-flex-shrink-0"
        >
          {{ model.subscribeButtonLabel }}
        </button>
      </div>
      <Modal
        v-model="termsModal"
        title=""
        :is-title-centered="false"
      >
        <div v-html="model.consentText"></div>
      </Modal>
    </template>
  </form>
</template>

<script>
import iconEmail from '@ds/svg/icons/stroke/email-action-unread.svg'
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import iconCircleCheck from '@ds/svg/icons/bold/check-circle-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import FormErrorLine from '@/CVI/WebCore/components/FormErrorLine.vue'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import api from '@/CVI/WebCore/lib/api'

export default {
  components: {
    Icon,
    FormErrorLine,
    Modal
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    blockId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      iconEmail,
      iconCheck,
      iconCircleCheck,
      success: false,
      error: false,
      emailInvalid: false,
      emailValid: false,
      termsValid: true,
      email: '',
      terms: false,
      termsModal: false,
      mailings: []
    }
  },
  computed: {
    isValid() {
      let valid = false
      if (!!this.terms && this.validateEmail() && this.model.mailingLists == null) {
        valid = true
      }
      if (this.model.mailingLists != null) {
        valid = !!this.mailings.length && !!this.terms && this.validateEmail()
      }
      return valid
    },
    mailingListsShown() {
      return this.model.mailingLists != null && Object.keys(this.model.mailingLists).length > 1
    }
  },
  mounted() {
    this.fillCheckboxes()
  },
  methods: {
    validateEmail() {
      const emailAddress = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.emailInvalid = !emailAddress.test(this.email)
      this.emailValid = emailAddress.test(this.email)
      return emailAddress.test(this.email)
    },
    onInputBlur() {
      if (this.email === '') {
        this.emailInvalid = false
      } else {
        this.validateEmail()
      }
    },
    resetSignUpForm() {
      this.email = ''
      this.terms = false
      this.error = false
      this.emailValid = false
    },
    fillCheckboxes() {
      if (this.model.mailingLists != null) {
        this.mailings = Object.keys(this.model.mailingLists)
      }
    },
    async submitForm() {
      if (this.isValid) {
        await api.signUp.subscribe({
          blockId: this.blockId,
          email: this.email,
          mailingLists: this.mailings
        })
          .then(() => {
            this.success = true
          })
          .catch((error) => {
            if (error.response) {
              this.error = true
            }
          })
      } else {
        this.termsValid = this.terms
        this.validateEmail()
      }
    }
  }
}
</script>
